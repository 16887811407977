import { EditorFontSize, EditorLineHeight, EditorLineWidth } from '@standardnotes/models';
import { NativeFeatureIdentifier } from '@standardnotes/features';
export var LocalPrefKey;
(function (LocalPrefKey) {
    LocalPrefKey["ListPaneCollapsed"] = "listPaneCollapsed";
    LocalPrefKey["NavigationPaneCollapsed"] = "navigationPaneCollapsed";
    LocalPrefKey["ActiveThemes"] = "activeThemes";
    LocalPrefKey["UseSystemColorScheme"] = "useSystemColorScheme";
    LocalPrefKey["UseTranslucentUI"] = "useTranslucentUI";
    LocalPrefKey["AutoLightThemeIdentifier"] = "autoLightThemeIdentifier";
    LocalPrefKey["AutoDarkThemeIdentifier"] = "autoDarkThemeIdentifier";
    LocalPrefKey["EditorMonospaceEnabled"] = "monospaceFont";
    LocalPrefKey["EditorLineHeight"] = "editorLineHeight";
    LocalPrefKey["EditorLineWidth"] = "editorLineWidth";
    LocalPrefKey["EditorFontSize"] = "editorFontSize";
})(LocalPrefKey || (LocalPrefKey = {}));
export const LocalPrefDefaults = {
    [LocalPrefKey.ListPaneCollapsed]: false,
    [LocalPrefKey.NavigationPaneCollapsed]: false,
    [LocalPrefKey.ActiveThemes]: [],
    [LocalPrefKey.UseSystemColorScheme]: false,
    [LocalPrefKey.UseTranslucentUI]: true,
    [LocalPrefKey.AutoLightThemeIdentifier]: 'Default',
    [LocalPrefKey.AutoDarkThemeIdentifier]: NativeFeatureIdentifier.TYPES.DarkTheme,
    [LocalPrefKey.EditorMonospaceEnabled]: false,
    [LocalPrefKey.EditorLineHeight]: EditorLineHeight.Normal,
    [LocalPrefKey.EditorLineWidth]: EditorLineWidth.FullWidth,
    [LocalPrefKey.EditorFontSize]: EditorFontSize.Normal,
};
